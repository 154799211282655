import {
  buildCreateSlice,
  asyncThunkCreator,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import {
  getPropertiesList as _getPropertiesList,
  getFilteredProperty as _getFilteredProperty,
  getPropertyDetailsData as _getPropertyDetailsData,
  updatePropertyDetailsData as _updatePropertyDetailsData,
  savePropertyDetailsData as _savePropertyDetailsData,
  getAllDistinctStateMaster as _getAllDistinctStateMaster,
  getAllDistinctCityByState as _getAllDistinctCityByState,
  getAllDistinctMicroMarketByCity as _getAllDistinctMicroMarketByCity,
  getAllDistinctLocalityByCityAndMicromarket as _getAllDistinctLocalityByCityAndMicromarket,
  deleteProperty as _deleteProperty,
  setPropertyDetails as _setPropertyDetails,
} from "../services/PropertyDetailsAPI";
import { get } from "lodash";
import { FormatPropertyDetails } from "../../utils/formatSearchData/FormatPropertyDetails";

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  loading: false,
  propertyDetailsData: [],
  isBuildingInfoEditable: true,
  currentPage: 1,
  pageSize: 10,
  propertyDetails: {},
  propertyId: null,
  transactionsCnt: 0,
  stateMaster: [],
  cityMaster: [],
  microMarketMaster: [],
  localityMaster: [],
  propertiesCnt: 0,
};

export const deleteProperty = createAsyncThunk(
  "propertyDetails/deleteProperty",
  async (propertyId, thunkAPI) => {
    try {
      const response = await _deleteProperty(propertyId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const propertyDetailsSlice = createSliceWithThunks({
  name: "propertyDetails",
  initialState,
  reducers: (create) => ({
    setBuildingInfoEditable: (state, action) => {
      state.isBuildingInfoEditable = action.payload;
    },
    getPropertiesList: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getPropertiesList(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.propertyDetailsData = [];
        },
        rejected: (state, action) => {
          state.loading = false;
          state.propertyDetailsData = [];
        },
        fulfilled: (state, action) => {
          state.propertyDetailsData = action?.payload?.data?.data;
          state.propertiesCnt = action?.payload?.data?.count;
          state.loading = false;
        },
      }
    ),
    setBuildingsInfo: (state, action) => {
      state.propertyDetails = {
        ...state.propertyDetails,
        [action?.payload?.name]: action?.payload?.value,
      };
    },
    updateBuildingsInfo: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const propertyId = thunkAPI.getState().propertyDetails?.propertyId;
          let data = await _updatePropertyDetailsData(_, propertyId);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          // state.loading = true;
        },
        rejected: (state, action) => {
          // state.loading = false;
        },
        fulfilled: (state, action) => {
          // state.loading = false;
        },
      }
    ),
    saveBuildingsInfo: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          let data = await _savePropertyDetailsData(_);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.propertyId = action?.payload?.data?.property?._id;
        },
      }
    ),
    getFilteredProperty: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getFilteredProperty(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.propertyDetailsData = [];
        },
        rejected: (state, action) => {
          state.loading = false;
          state.propertyDetailsData = [];
        },
        fulfilled: (state, action) => {
          state.propertyDetailsData = action?.payload?.data?.data;
          state.propertiesCnt = action?.payload?.data?.count;
          state.loading = false;
        },
      }
    ),
    setCurrentPage: (state, action) => {
      state.currentPage = action?.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action?.payload;
    },
    getPropertyDetailsData: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getPropertyDetailsData(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.propertyDetails = {};
        },
        rejected: (state, action) => {
          state.loading = false;
          state.propertyDetails = {};
        },
        fulfilled: (state, action) => {
          state.propertyDetails = FormatPropertyDetails(
            action?.payload?.data?.[0]
          );
          state.loading = false;
        },
      }
    ),
    setPropertyId: (state, action) => {
      state.propertyId = action.payload;
    },
    setPropertyDetails: (state, action) => {
      state.propertyDetails = action?.payload;
    },

    setPropertyInitialState: (state, action) => {
      state.loading = false;
      state.propertyDetailsData = [];
      state.isBuildingInfoEditable = true;
      state.currentPage = 1;
      state.pageSize = 10;
      state.propertyDetails = {};
      state.propertyId = null;
      state.stateMaster = [];
      state.cityMaster = [];
      state.microMarketMaster = [];
      state.localityMaster = [];
    },
    setTransactionCnt: (state, action) => {
      state.transactionsCnt = action?.payload;
    },
    setLoading: (state, action) => {
      state.loading = action?.payload;
    },
    extraReducers: (builder) => {
      builder.addCase(deleteProperty.fulfilled, (state, action) => {
        state.propertyDetailsData = state.propertyDetailsData.filter(
          (property) => property._id !== action.payload._id
        );
      });
    },
    getAllDistinctStateMaster: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          let data = await _getAllDistinctStateMaster();
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.stateMaster = [];
        },
        rejected: (state, action) => {
          state.stateMaster = [];
        },
        fulfilled: (state, action) => {
          const stateMasterArray = action.payload.data.map((item) => ({
            label: item.state,
            key: item._id,
            value: item._id,
          }));
          state.stateMaster = stateMasterArray; // Update state with formatted data
        },
      }
    ),
    getAllDistinctCityByState: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          let data = await _getAllDistinctCityByState(_);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.cityMaster = [];
        },
        rejected: (state, action) => {
          state.cityMaster = [];
        },
        fulfilled: (state, action) => {
          const cityMasterArray = [];
          const cities = action.payload.data || [];
          if (Array.isArray(cities) && cities.length > 0) {
            for (const city of cities) {
              const element = {
                label: city.city,
                key: city._id,
                value: city._id,
              };
              cityMasterArray.push(element);
            }
          }
          state.cityMaster = cityMasterArray;
        },
      }
    ),
    getAllDistinctMicroMarketByCity: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const city =
            thunkAPI.getState().propertyDetails?.propertyDetails?.city;
          let data = await _getAllDistinctMicroMarketByCity(_);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.microMarketMaster = [];
        },
        rejected: (state, action) => {
          state.microMarketMaster = [];
        },
        fulfilled: (state, action) => {
          const microMarketMasterArray = [];
          const data = action?.payload?.data || [];
          if (data.length > 0) {
            for (let index = 0; index < data.length; index++) {
              const element = {
                label: data[index]?.microMarket,
                key: data[index]?._id,
                value: data[index]?._id,
              };
              microMarketMasterArray.push(element);
            }
          }
          state.microMarketMaster =
            data.length === 1 && data[0] === "" ? [] : microMarketMasterArray;
        },
      }
    ),
    getAllDistinctLocalityByCityAndMicromarket: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const city =
            thunkAPI.getState().propertyDetails?.propertyDetails?.city;
          const microMarket =
            thunkAPI.getState().propertyDetails?.propertyDetails?.microMarket;
          let data = await _getAllDistinctLocalityByCityAndMicromarket(
            city,
            microMarket,
            _
          );
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.localityMaster = [];
        },
        rejected: (state, action) => {
          state.localityMaster = [];
        },
        fulfilled: (state, action) => {
          const localityMasterArray = action?.payload?.data?.map((locality) => ({
            label: locality?.locality,
            key: locality?._id,
            value: locality?._id,
            microMarket: locality?.microMarketId?.microMarket,
            microMarketId: locality?.microMarketId?._id,
            pincodeId: locality?.pincodeId?._id,
            pincode: locality?.pincodeId?.pincode,
          }));
          state.localityMaster = localityMasterArray;
        },
      }
    ),
  }),
  extraReducers: (builder) => {
    builder
      .addCase(getPropertiesList.fulfilled, (state, action) => {
        state.propertyDetailsData = action.payload.data.data;
        state.propertiesCnt = action.payload.data.count;
        state.loading = false;
      })
      .addCase(getFilteredProperty.fulfilled, (state, action) => {
        state.propertyDetailsData = action.payload.data.data;
        state.propertiesCnt = action.payload.data.count;
        state.loading = false;
      })
      .addCase(getPropertyDetailsData.fulfilled, (state, action) => {
        state.propertyDetails = FormatPropertyDetails(action.payload.data[0]);
        state.loading = false;
      })
      .addCase(deleteProperty.fulfilled, (state, action) => {
        state.propertyDetailsData = state.propertyDetailsData.filter(
          (property) => property._id !== action.payload._id
        );
      });
  },
});

export const {
  getPropertiesList,
  setBuildingInfoEditable,
  setBuildingsInfo,
  updateBuildingsInfo,
  getFilteredProperty,
  setCurrentPage,
  setPageSize,
  getPropertyDetailsData,
  setPropertyId,
  setPropertyDetails,
  setPropertyInitialState,
  setTransactionCnt,
  saveBuildingsInfo,
  setLoading,
  getAllDistinctStateMaster,
  getAllDistinctCityByState,
  getAllDistinctMicroMarketByCity,
  getAllDistinctLocalityByCityAndMicromarket,
} = propertyDetailsSlice.actions;
export default propertyDetailsSlice.reducer;

import React, { useEffect, useState } from "react";
import { Card, Row, Col, Select, Form } from "antd";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { getDashboardCounts, getUserDetails } from "../../feature/services/DashboardDetailsAPI";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllDistinctStateMaster,
    getAllDistinctCityByState,
    getAllDistinctLocalityByCityAndMicromarket,
} from "../../feature/slice/MasterAddressSlice";
import { setDashboardFilter } from "../../feature/slice/DashboardDetailsSlice";

const { Option } = Select;

const Dashboard = () => {
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [locality, setLocality] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [topCount, setTopCount] = useState(5);
    const [barChartData, setBarChartData] = useState([]);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const { stateMaster, cityMaster, localityMaster } = useSelector(
        (store) => store.masterAddressDetails
    );

    const [cardData, setCardData] = useState([
        { title: "Property", count: "" },
        { title: "Transaction", count: "" },
        { title: "Company", count: "" },
        { title: "Connect", count: "" }
    ]);

    useEffect(() => {
        dispatch(getAllDistinctStateMaster());
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        if (state) {
            dispatch(getAllDistinctCityByState(state));
        }
    }, [state, dispatch]);

    useEffect(() => {
        if (city) {
            dispatch(getAllDistinctLocalityByCityAndMicromarket({ city }));
        }
    }, [city, dispatch]);

    const fetchData = async () => {
        try {
            const [dashboardData, userDetails] = await Promise.all([
                getDashboardCounts({ state, city, locality }),
                getUserDetails()
            ]);

            if (Array.isArray(dashboardData)) {
                setBarChartData(dashboardData);
                setCardData(dashboardData.map(item => ({ title: item.title, count: item.count })));
            }

            if (Array.isArray(userDetails)) {
                setUserData(userDetails);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [state, city, locality]);

    const handleTopCountChange = (value) => {
        setTopCount(value);
        const sortedData = [...userData].sort((a, b) => b.totalCount - a.totalCount);
        setUserData(sortedData.slice(0, value));
    };

    useEffect(() => {
        handleTopCountChange(topCount);
    }, [userData, topCount]);

    const handleStateChange = (value) => {
        form.setFieldsValue({ city: null, locality: null });
        setCity(null);
        setLocality(null);
        setState(value);
        dispatch(getAllDistinctCityByState(value));
        dispatch(setDashboardFilter({ name: "state", value }));
    };

    const handleCityChange = (value) => {
        form.setFieldsValue({ locality: null });
        setCity(value);
        setLocality(null);
        dispatch(getAllDistinctLocalityByCityAndMicromarket({ city: value }));
        dispatch(setDashboardFilter({ name: "city", value }));
    };

    const handleLocalityChange = (value) => {
        setLocality(value);
        dispatch(setDashboardFilter({ name: 'locality', value }));
    };

    const renderStateOptions = () =>
        stateMaster?.map((state) => (
            <Select.Option key={state.key} value={state.value}>
                {state.label}
            </Select.Option>
        ));

    const renderCityOptions = () =>
        cityMaster?.map((city) => (
            <Select.Option key={city.key} value={city.value}>
                {city.label}
            </Select.Option>
        ));

    const renderLocalityOptions = () =>
        localityMaster.map((locality) => (
            <Select.Option key={locality.key} value={locality.value}>
                {locality.label}
            </Select.Option>
        ));

    const renderBarChart = (data) => (
        <BarChart width={1000} height={500} style={{ marginLeft: "auto", marginRight: "auto" }} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="title" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#fb923c" barSize={100} />
        </BarChart>
    );

    useEffect(() => {
        const filterData = () => {
            let data = barChartData;

            if (state) {
                data = data.filter(item => item.state === state);
            }

            if (city) {
                data = data.filter(item => item.city === city);
            }

            if (locality) {
                data = data.filter(item => item.locality === locality);
            }

            setFilteredData(data);
        };

        filterData();
    }, [state, city, locality, barChartData]);

    const renderUserBarChart = (data) => (
        <BarChart width={1000} height={500} style={{ marginLeft: "auto", marginRight: "auto" }} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="userName" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="totalCount" fill="#fb923c" barSize={50} />
        </BarChart>
    );

    return (
        <React.Fragment>
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <div className="m-4">
                    <div className="pl-3">
                        <h1 style={{ fontSize: "18px", fontWeight: "bold" }}>Dashboard</h1>
                    </div>
                    <Row gutter={16} style={{ margin: "20px 0" }}>
                        {cardData.map((item) => (
                            <Col span={6} key={item.title}>
                                <Card title={item.title} bordered={false} style={{ textAlign: "center", boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                                    <h2>{item.count ? item.count : 0}</h2>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <div className="mb-4 p-4" style={{ backgroundColor: "white", boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius: "10px" }}>
                        <h1>All Data</h1>
                        <Row gutter={16} style={{ marginBottom: "20px" }}>
                            <Form form={form} layout="vertical" className="flex flex-row w-full justify-around items-center gap-4 p-4">
                                <Form.Item name="state" label="State" className="w-full">
                                    <Select placeholder="Select State" onChange={handleStateChange}>
                                        {renderStateOptions()}
                                    </Select>
                                </Form.Item>
                                <Form.Item name="city" label="City" className="w-full">
                                    <Select placeholder="Select City" onChange={handleCityChange}>
                                        {renderCityOptions()}
                                    </Select>
                                </Form.Item>
                                <Form.Item name="locality" label="Locality" className="w-full">
                                    <Select placeholder="Select Locality" onChange={handleLocalityChange}>
                                        {renderLocalityOptions()}
                                    </Select>
                                </Form.Item>
                            </Form>
                        </Row>
                        <div style={{ padding: '30px 0', boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius: "10px" }}>
                            {renderBarChart(filteredData.length > 0 ? filteredData : barChartData)}
                        </div>
                    </div>
                    <div className="mb-4 p-4" style={{ backgroundColor: "white", boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius: "10px" }}>
                        <h1>User Actions Bar Chart</h1>
                        <Select value={topCount} onChange={handleTopCountChange} style={{ width: 120 }}>
                            <Select.Option value={5}>Top 5</Select.Option>
                            <Select.Option value={10}>Top 10</Select.Option>
                            <Select.Option value={15}>Top 15</Select.Option>
                        </Select>
                        {renderUserBarChart(userData)}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;

export const FormatPropertyDetails = (data) => {
  const propertyImageData = [];
  if (
    data?.propertyDetail?.propertyImages &&
    data?.propertyDetail?.propertyImages?.length > 0
  ) {
    for (
      let index = 0;
      index < data?.propertyDetail?.propertyImages?.length;
      index++
    ) {
      const element = {
        uid: index,
        name: data?.propertyDetail?.propertyImages?.[index]?.split(".com/")[1],
        status: "done",
        url: data?.propertyDetail?.propertyImages?.[index],
        isProfile: false,
      };
      propertyImageData.push(element);
    }
  }
  if (data?.propertyDetail?.profileImage) {
    propertyImageData.push({
      uid: propertyImageData?.length,
      name: data?.propertyDetail?.profileImage?.split(".com/")[1],
      status: "done",
      url: data?.propertyDetail?.profileImage,
      isProfile: true,
    });
  }

  let originalPropertyImages = [];
  if (
    data?.propertyDetail?.propertyImages &&
    data?.propertyDetail?.profileImage
  ) {
    originalPropertyImages = [
      ...data?.propertyDetail?.propertyImages,
      data?.propertyDetail?.profileImage,
    ];
  } else if (
    data?.propertyDetail?.propertyImages &&
    !data?.propertyDetail?.profileImage
  ) {
    originalPropertyImages = [...data?.propertyDetail?.propertyImages];
  } else if (
    !data?.propertyDetail?.propertyImages &&
    data?.propertyDetail?.profileImage
  ) {
    originalPropertyImages = [data?.propertyDetail?.profileImage];
  }

  return {
    buildingName: data?.propertyDetail?.buildingName,
    numberOfTransactions: data?.propertyDetail?.numberOfTransactions,
    totalTransactionAmount: data?.propertyDetail?.totalTransactionsAmount,
    parkingInformationOfFourWheeler: data?.propertyDetail?.fourWheelerParking,
    loadingPercent: data?.propertyDetail?.loading,
    averageCAM: data?.morePropertyInfo?.averageCamChargeable,
    powerBackup: data?.propertyDetail?.powerBackup,
    buildingType: data?.propertyDetail?.buildingType,
    buildingAge: data?.propertyDetail?.buildingAge,
    longitude: data?.address?.longitude,
    locality: data?.address?.locality,
    pincode: data?.address?.pincode,
    pincodeValue: data?.address?.pincodeValue,
    microMarket: data?.address?.micromarket?.join(", "),
    totalChargeableArea: data?.propertyDetail?.totalChargeableArea,
    amenities: data?.propertyDetail?.amenities,
    developer: data?.propertyDetail?.developer,
    avgRentRate: data?.morePropertyInfo?.averageRentRate,
    acType: data?.propertyDetail?.acType,
    buildingClassification: data?.propertyDetail?.buildingClassification,
    proposedAvailabilityDate: data?.propertyDetail?.proposedAvailabilityDate,
    propertyDescription: data?.propertyDetail?.propertyDescription,
    plotNo: data?.address?.plot,
    city: data?.address?.city,
    latitude: data?.address?.latitude,
    buildingStructure: data?.propertyDetail?.buildingStructure,
    parkingInformationOfTwoWheeler: data?.propertyDetail?.twoWheelerParking,
    efficiency: data?.propertyDetail?.efficiency,
    avgSaleRate: data?.morePropertyInfo?.averageSaleRate,
    commonCafeteria: data?.propertyDetail?.commonCafeteria,
    remarks: data?.propertyDetail?.remarks,
    buildingStatus: data?.propertyDetail?.buildingStatus,
    street: data?.address?.street,
    blockNo: data?.address?.block,
    state: data?.address?.state,
    buildingStats: data?.propertyDetail?.buildingStats,
    address: data?.address?.complete_address,
    propertyImages: propertyImageData,
    originalPropertyImages,
    gradeOfBuilding: data?.propertyDetail?.gradeOfBuilding,
    totalUnit: data?.propertyDetail?.totalUnit,
    avgCAMChargeable: data?.morePropertyInfo?.avgCAMChargeable,
    avgCAMCarpet: data?.morePropertyInfo?.avgCAMCarpet,
    avgFloorPlateCarpet: data?.morePropertyInfo?.avgFloorPlateCarpet,
    avgFloorPlateChargeable: data?.morePropertyInfo?.avgFloorPlateChargeable,
    name: data?.propertyDetail?.representativeName,
    email: data?.propertyDetail?.representativeEmail,
    contact: data?.propertyDetail?.representativeContact,
    aadharNo: data?.propertyDetail?.representativeAadharNo,
    PAN: data?.propertyDetail?.representativePAN,
  };
};

import React, { useEffect, useState } from "react";
import { Table, Typography, Pagination, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
    setCurrentPage,
    setPageSize,
    fetchReportDetails,
    setReportDetails,
} from "../../feature/slice/ReportDetailsSlice";
import Header from "../../components/header/Header";
import dayjs from 'dayjs';
import { setSelectedNav } from "../../feature/slice/FiltersSlice";
import SideNav from "../../components/sideNav/SideNav";

const ReportDetailsCard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userId, userName, category } = useParams();
    const location = useLocation();
    const [dateRange, setDateRange] = useState(location.state ? [location.state.start, location.state.end] : [null, null]);
    const [startDate, endDate] = dateRange || [];

    const { reportDetails, currentPage, pageSize } = useSelector(state => state.reportDetails);

    const categoryMapping = {
        propertyInfo: 'Property',
        transactionInfo: 'Transaction',
        mcaCompanyInfo: 'Company',
        personInfo: 'Connect',
        addressInfo: 'Address',
    };

    const displayCategory = categoryMapping[category] || category;
    useEffect(() => {
        dispatch(setReportDetails({ data: [], count: 0 }));
    }, [dispatch]);

    useEffect(() => {
        if (userId && category) {
            dispatch(fetchReportDetails({ userId, category, page: currentPage, pageSize, startDate, endDate }));
        }
    }, [dispatch, userId, category, currentPage, pageSize, startDate, endDate]);

    const getEntityUrl = (collectionName) => {
        switch (collectionName) {
            case "propertyInfo":
                return "propertyDetails";
            case "transactionInfo":
                return "transactionDetails";
            case "mcaCompanyInfo":
                return "companyDetails";
            case "personInfo":
                return "connectDetails";
            case "mcaDirectorInfo":
                return "directorDetails";
            default:
                return null;
        }
    };

    const columns = [
        {
            title: "Field",
            dataIndex: "field",
            key: "field",
            width: 150,
            render: (text) => <Typography.Text>{text}</Typography.Text>,
        },
        {
            title: "Entity",
            dataIndex: "documentId",
            key: "documentId",
            width: 120,
            render: (text, record) => {
                const collectionUrl = getEntityUrl(record.collectionName);
                const url = collectionUrl ? `/${collectionUrl}/${record.documentId}` : null;

                return (
                    <div
                        style={{
                            cursor: url ? "pointer" : "default",
                        }}
                        onClick={() => {
                            if (url) {
                                window.open(url, '_blank');
                            }
                        }}
                    >
                        {text}
                    </div>
                );
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            width: 120,
            render: (text) => <Typography.Text>{text}</Typography.Text>,
        },
        {
            title: "Previous Value",
            dataIndex: "previousValue",
            key: "previousValue",
            width: 260,
            render: (text) => <Typography.Text>{text}</Typography.Text>,
        },
        {
            title: "Current Value",
            dataIndex: "currentValue",
            key: "currentValue",
            width: 260,
            render: (text) => <Typography.Text>{text}</Typography.Text>,
        },
        {
            title: "Last Updated Date",
            dataIndex: "lastUpdatedOn",
            key: "lastUpdatedOn",
            width: 150,
            render: (text) => <Typography.Text>{dayjs(text).format('DD-MM-YYYY - HH:mm:ss')}</Typography.Text>,
        },
    ];

    // Handle pagination changes
    const handlePaginationChange = (page, pageSize) => {
        dispatch(setCurrentPage(page));
        dispatch(setPageSize(pageSize));
    };

    const onShowSizeChange = (current, pageSize) => {
        dispatch(setPageSize(pageSize));
        dispatch(setCurrentPage(1));
    };

    return (
        <>
            <div>
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <h1 className="text-xl">Report Details of : <span className="font-bold ml-2 "> {userName}</span></h1>
                            <Typography.Text className="text-xl mt-4">Category: {displayCategory}</Typography.Text>
                        </div>
                        <Button onClick={() =>
                            dispatch(setCurrentPage(1)) &&
                            navigate(-1)}>Back</Button>
                    </div>
                    <Table
                        className="m-5"
                        bordered
                        dataSource={reportDetails?.data}
                        columns={columns}
                        size="middle"
                        pagination={false}
                        rowKey="documentId"
                    />
                    {reportDetails.data && reportDetails.data.length > 0 && (
                        <Pagination
                            showSizeChanger
                            onShowSizeChange={onShowSizeChange}
                            onChange={handlePaginationChange}
                            current={currentPage}
                            pageSize={pageSize}
                            total={reportDetails.count}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
export default ReportDetailsCard;
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import PropertyDetailsReducer from "../slice/PropertyDetailsSlice";
import CompanyDetailsReducer from "../slice/CompanyDetailsSlice";
import ConnectDetailsReducer from "../slice/ConnectDetailsSlice";
import UserReducer from "../slice/UserSlice";
import FiltersReducer from "../slice/FiltersSlice";
import TransactionDetailsReducer from "../slice/TransactionDetailsSlice";
import DirectorDetailsReducer from "../slice/DirectorDetailsSlice";
import masterAddressDetailsReducer from '../slice/MasterAddressSlice';
import ReportDetailsReducer from "../slice/ReportDetailsSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  propertyDetails: PropertyDetailsReducer,
  user: UserReducer,
  filters: FiltersReducer,
  transactionDetails: TransactionDetailsReducer,
  companyDetails: CompanyDetailsReducer,
  connectDetails: ConnectDetailsReducer,
  directorDetails: DirectorDetailsReducer,
  reportDetails: ReportDetailsReducer,
  masterAddressDetails: masterAddressDetailsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
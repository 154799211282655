import React, { useEffect, useState } from "react";
import { Table, Typography, Pagination, Button, Spin, message, Modal } from "antd";
import { useNavigate, useParams } from "react-router";
import "./Transactions.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPage,
  setPageSize,
  setTransactionInitialState,
  setTransactionId,
  getAllTransactions,
  getFilteredTransaction,
  deleteTransaction,
} from "../../feature/slice/TransactionDetailsSlice";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { bulkUploadTransactions, getTransactionDocument } from "../../feature/services/TransactionDetailsAPI";
import { CommonFilter } from '../../components/filterCard/FilterCard'
import { ReloadOutlined } from "@ant-design/icons";
import { MdDeleteForever } from "react-icons/md";
import BulkUpload from "../../assets/bulk.svg";
import AddProperty from "../../assets/add.svg";
import DownloadIcon from '../../assets/download.svg';

export const handleViewHtml = async (transactionData) => {

  const data = {
    transactionId: transactionData.id,
    documentType: transactionData.type
  }

  const response = await getTransactionDocument(data)
  if (response.status === 200) {

    const blob = response.data;

    // Create a URL from the blob
    const pdfUrl = URL.createObjectURL(blob);

    const newWindow = window.open(
      "_blank",
      `resizable=yes,scrollbars=yes`
    );

    const title = transactionData.propertyName && transactionData.documentNo
      ? `${transactionData.propertyName} - ${transactionData.documentNo}`
      : transactionData.propertyName
        ? transactionData.propertyName
        : transactionData.documentNo
          ? transactionData.documentNo
          : 'PDF Viewer';

    newWindow.document.write(`
      <html>
        <head>
          <title>${title}</title>
        </head>
        <body>
          <object data="${pdfUrl}" type="application/pdf" width="100%" height="100%">
          </object>
        </body>
      </html>
    `);
    newWindow.document.close();
  }
};


const Transactions = (props) => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, transactionsDetailsData, currentPage, pageSize, transactionsCnt, documentNameMaster } =
    useSelector((store) => store.transactionDetails);
  const { transactionFilters, localitiesData, microMarketsData } = useSelector(
    (store) => store.filters
  );

  const [messageApi, contextHolder] = message.useMessage();
  const [filterTransaction, setFilterTransaction] = useState({
    buildingName: null,
    buildingNameFilter: 'contains',
    documentNo: null,
    documentNoFilter: 'equals',
    documentName: [],
    registrationStartDate: null,
    registrationEndDate: null,
    locality: [],
    proposedAvailabilityStartDate: null,
    proposedAvailabilityEndDate: null,
    unitNo: null,
    unitNoFilter: 'contains',
    floorNo: null,
    floorNoFilter: 'contains',
    saleRate: null,
    saleRateFilter: 'equals',
    rentRate: null,
    rentRateFilter: 'equals',
    completionRatio: null,
    completionRatioFilter: 'equals',
    chargeableSquareFeet: null,
    chargeableSquareFeetFilter: 'equals',
    microMarket: [],
    cam: null,
    camFilter: 'equals',
    createdAtStartDate: null,
    createdAtEndDate: null
  })

  const handleDelete = (transactionId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this transaction?",
      onOk: async () => {
        try {
          const response = await dispatch(deleteTransaction(transactionId));
          messageApi.success(response.message || "Transaction deleted successfully");

          setTimeout(() => {
            dispatch(setCurrentPage(1));
            fetchTransactions(currentPage, pageSize);
          }, 3000);
        } catch (error) {
          messageApi.error(error.message || "Failed to delete transaction");
        }
      },
    });
  };

  const [filterVisible, setFilterVisible] = useState({});
  const handleFilterDropdownVisible = (columnKey, visible) => {
    setFilterVisible(prev => ({
      ...prev,
      [columnKey]: visible,
    }));
  };

  const headerClassName = (isVisible) => isVisible ? 'highlight-header' : '';

  const handleView = (transactionId) => {
    navigateTo(`/transactionDetails/${transactionId}`);
  };

  const columns = [
    {
      title: <div className={headerClassName(filterVisible.propertyName)}>Building Name</div>,
      dataIndex: "propertyName",
      width: 200,
      editable: true,
      fixed: "left",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterTransaction.buildingName}
          filterType={filterTransaction.buildingNameFilter}
          onFilterChange={(value) => {
            setFilterTransaction({ ...filterTransaction, buildingName: value });
          }}
          onFilterTypeChange={(value) => setFilterTransaction({ ...filterTransaction, buildingNameFilter: value })}
          onApply={() => {
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10);
            confirm();
            handleFilterDropdownVisible('propertyName', false);
          }}
          onReset={() => {
            const updatedFilterTransaction = { ...filterTransaction, buildingName: null, buildingNameFilter: 'contains' };
            setFilterTransaction(updatedFilterTransaction);
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10, updatedFilterTransaction);
            clearFilters();
            confirm();
            handleFilterDropdownVisible('propertyName', false);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
          placeholder="Enter the Building name here"
        />
      ),
      filterDropdownVisible: filterVisible.propertyName,
      onFilterDropdownVisibleChange: (visible) => handleFilterDropdownVisible('propertyName', visible),
      filterSearch: true,
    },
    {
      title: <div className={headerClassName(filterVisible.documentNo)}>Document No</div>,
      dataIndex: "documentNo",
      width: 100,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="number"
          filterValue={filterTransaction.documentNo}
          filterType={filterTransaction.documentNoFilter}
          onFilterChange={(value) => {
            setFilterTransaction({ ...filterTransaction, documentNo: value });
          }}
          onFilterTypeChange={(value) => setFilterTransaction({ ...filterTransaction, documentNoFilter: value })}
          onApply={() => {
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10);
            confirm();
            handleFilterDropdownVisible('documentNo', false);
          }}
          onReset={() => {
            const updatedFilterTransaction = { ...filterTransaction, documentNo: null, documentNoFilter: 'equals' };
            setFilterTransaction(updatedFilterTransaction);
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10, updatedFilterTransaction);
            clearFilters();
            confirm();
            handleFilterDropdownVisible('documentNo', false);
          }}
          filterTypeOptions={[
            { value: 'equals', label: 'Equal' },
            { value: 'lessthan', label: 'Less Than' },
            { value: 'greaterthan', label: 'Greater Than' },
          ]}
          placeholder="Enter the document number here"
        />
      ),
      filterDropdownVisible: filterVisible.documentNo,
      onFilterDropdownVisibleChange: (visible) => handleFilterDropdownVisible('documentNo', visible),
      filterSearch: true,
    },
    {
      title: <div className={headerClassName(filterVisible.documentName)}>Document Name</div>,
      dataIndex: "documentName",
      width: 150,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterTransaction.documentName || []}
          onFilterChange={(value) => {
            setFilterTransaction({ ...filterTransaction, documentName: value });
          }}
          onApply={() => {
            fetchTransactions(1, 10);
            handleFilterDropdownVisible('documentName', false);
            confirm();
          }}
          onReset={() => {
            const updatedFilterTransaction = { ...filterTransaction, documentName: [] };
            setFilterTransaction(updatedFilterTransaction);
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10, updatedFilterTransaction);
            clearFilters();
            handleFilterDropdownVisible('documentName', false);
            confirm();
          }}
          inputType="select"
          mode="multiple"
          placeholder="Select a Document Name"
          selectOptions={documentNameMaster.map(document => ({
            value: document.value,
            label: document.label,
          }))}
        />
      ),
      filterDropdownVisible: filterVisible.documentName,
      onFilterDropdownVisibleChange: (visible) => handleFilterDropdownVisible('documentName', visible),
      onFilter: (value, record) => record.documentName.includes(value),
    },
    {
      title: <div className={headerClassName(filterVisible.registrationDate)}>Registration Date</div>,
      dataIndex: "registrationDate",
      width: 120,
      editable: true,
      render: (registrationDate) =>
        registrationDate ? dayjs(registrationDate).format("DD-MM-YYYY") : null,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="dateRange"
          startDate={filterTransaction.registrationStartDate}
          endDate={filterTransaction.registrationEndDate}
          onStartDateChange={(date) =>
            setFilterTransaction({
              ...filterTransaction,
              registrationStartDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute"),
            })
          }
          onEndDateChange={(date) =>
            setFilterTransaction({
              ...filterTransaction,
              registrationEndDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute"),
            })
          }
          onApply={() => {
            fetchTransactions(1, 10, filterTransaction);
            handleFilterDropdownVisible('registrationDate', false);
            confirm();
          }}
          onReset={() => {
            const updatedFilterTransaction = {
              ...filterTransaction,
              registrationStartDate: null,
              registrationEndDate: null,
            };
            setFilterTransaction(updatedFilterTransaction);
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10, updatedFilterTransaction);
            clearFilters();
            handleFilterDropdownVisible('registrationDate', false);
            confirm();
          }}
        />
      ),
      filterDropdownVisible: filterVisible.registrationDate,
      onFilterDropdownVisibleChange: (visible) =>
        handleFilterDropdownVisible('registrationDate', visible),
    },
    {
      title: <div className={headerClassName(filterVisible.locality)}>Locality</div>,
      dataIndex: "locality",
      width: 100,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterTransaction.locality || []}
          onFilterChange={(value) => {
            setFilterTransaction({ ...filterTransaction, locality: value });
          }}
          onApply={() => {
            fetchTransactions(1, 10);
            handleFilterDropdownVisible('locality', false);
            confirm();
          }}
          onReset={() => {
            const updatedFilterTransaction = { ...filterTransaction, locality: [] };
            setFilterTransaction(updatedFilterTransaction);
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10, updatedFilterTransaction);
            clearFilters();
            handleFilterDropdownVisible('locality', false);
            confirm();
          }}
          inputType="select"
          mode="multiple"
          placeholder="Select a Locality"
          selectOptions={localitiesData?.map((locality) => ({
            value: locality.value,
            label: locality.title,
          }))}
        />
      ),
      filterDropdownVisible: filterVisible.locality,
      onFilterDropdownVisibleChange: (visible) => handleFilterDropdownVisible('locality', visible),
      onFilter: (value, record) => record.locality.includes(value),
    },
    {
      title: <div className={headerClassName(filterVisible.proposedAvailabilityDate)}>Proposed Availability Date</div>,
      dataIndex: "proposedAvailabilityDate",
      width: 150,
      editable: true,
      render: (proposedAvailabilityDate) =>
        proposedAvailabilityDate
          ? dayjs(proposedAvailabilityDate).format("DD-MM-YYYY")
          : null,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="dateRange"
          startDate={filterTransaction.proposedAvailabilityStartDate}
          endDate={filterTransaction.proposedAvailabilityEndDate}
          onStartDateChange={(date) =>
            setFilterTransaction({
              ...filterTransaction,
              proposedAvailabilityStartDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute"),
            })
          }
          onEndDateChange={(date) =>
            setFilterTransaction({
              ...filterTransaction,
              proposedAvailabilityEndDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute"),
            })
          }
          onApply={() => {
            fetchTransactions(1, 10, filterTransaction);
            handleFilterDropdownVisible('proposedAvailabilityDate', false);
            confirm();
          }}
          onReset={() => {
            const updatedFilterTransaction = {
              ...filterTransaction,
              proposedAvailabilityStartDate: null,
              proposedAvailabilityEndDate: null,
            };
            setFilterTransaction(updatedFilterTransaction);
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10, updatedFilterTransaction);
            clearFilters();
            handleFilterDropdownVisible('proposedAvailabilityDate', false);
            confirm();
          }}
        />
      ),
      filterDropdownVisible: filterVisible.proposedAvailabilityDate,
      onFilterDropdownVisibleChange: (visible) =>
        handleFilterDropdownVisible('proposedAvailabilityDate', visible),
    },
    {
      title: "Buyer Name",
      dataIndex: "buyers",
      width: 100,
      editable: true,
    },
    {
      title: "Seller Name",
      dataIndex: "sellers",
      width: 100,
      editable: true,
    },
    {
      title: <div className={headerClassName(filterVisible.unitNo)}>Unit No.</div>,
      dataIndex: "unitNo",
      width: 100,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterTransaction.unitNo}
          filterType={filterTransaction.unitNoFilter}
          onFilterChange={(value) => setFilterTransaction({ ...filterTransaction, unitNo: value })}
          onFilterTypeChange={(value) => setFilterTransaction({ ...filterTransaction, unitNoFilter: value })}
          onApply={() => {
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10);
            handleFilterDropdownVisible('unitNo', false);
            confirm();
          }}
          onReset={() => {
            const updatedFilterTransaction = { unitNo: null, unitNoFilter: 'contains' };
            setFilterTransaction(updatedFilterTransaction);
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10, updatedFilterTransaction);
            clearFilters();
            handleFilterDropdownVisible('unitNo', false);
            confirm();
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
          placeholder="Enter the unit no here"
        />
      ),
      filterDropdownVisible: filterVisible.unitNo,
      onFilterDropdownVisibleChange: (visible) => handleFilterDropdownVisible('unitNo', visible),
      filterSearch: true,
    },
    {
      title: <div className={headerClassName(filterVisible.floorNo)}>Floor No.</div>,
      dataIndex: "floorNo",
      width: 100,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterTransaction.floorNo}
          filterType={filterTransaction.floorNoFilter}
          onFilterChange={(value) => setFilterTransaction({ ...filterTransaction, floorNo: value })}
          onFilterTypeChange={(value) => setFilterTransaction({ ...filterTransaction, floorNoFilter: value })}
          onApply={() => {
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10);
            handleFilterDropdownVisible('floorNo', false);
            confirm();
          }}
          onReset={() => {
            const updatedFilterTransaction = { floorNo: null, floorNoFilter: 'contains' };
            setFilterTransaction(updatedFilterTransaction);
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10, updatedFilterTransaction);
            clearFilters();
            handleFilterDropdownVisible('floorNo', false);
            confirm();
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
          placeholder="Enter the floor no here"
        />
      ),
      filterDropdownVisible: filterVisible.floorNo,
      onFilterDropdownVisibleChange: (visible) => handleFilterDropdownVisible('floorNo', visible),
      filterSearch: true,
    },
    {
      title: <div className={headerClassName(filterVisible.saleRate)}>Sale Rate</div>,
      dataIndex: "saleRate",
      width: 100,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="number"
          filterValue={filterTransaction.saleRate}
          filterType={filterTransaction.saleRateFilter}
          onFilterChange={(value) => setFilterTransaction({ ...filterTransaction, saleRate: value })}
          onFilterTypeChange={(value) => setFilterTransaction({ ...filterTransaction, saleRateFilter: value })}
          onApply={() => {
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10);
            handleFilterDropdownVisible('saleRate', false);
            confirm();
          }}
          onReset={() => {
            const updatedFilterTransaction = { saleRate: null, saleRateFilter: 'equals' };
            setFilterTransaction(updatedFilterTransaction);
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10, updatedFilterTransaction);
            clearFilters();
            handleFilterDropdownVisible('saleRate', false);
            confirm();
          }}
          filterTypeOptions={[
            { value: 'equals', label: 'Equals' },
            { value: 'lessthan', label: 'Less Than' },
            { value: 'greaterthan', label: 'Greater Than' },
          ]}
          placeholder="Enter the sale rate here"
        />
      ),
      filterDropdownVisible: filterVisible.saleRate,
      onFilterDropdownVisibleChange: (visible) => handleFilterDropdownVisible('saleRate', visible),
      filterSearch: true,
    },
    {
      title: "Rent Rate",
      dataIndex: "rentRate",
      width: 100,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="number"
          filterValue={filterTransaction.rentRate}
          filterType={filterTransaction.rentRateFilter}
          onFilterChange={(value) => setFilterTransaction({ ...filterTransaction, rentRate: value })}
          onFilterTypeChange={(value) => setFilterTransaction({ ...filterTransaction, rentRateFilter: value })}
          onApply={() => {
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10);
            confirm();
          }}
          onReset={() => {
            const updatedFilterTransaction = { ...filterTransaction, rentRate: null, rentRateFilter: 'equals' }
            setFilterTransaction(updatedFilterTransaction)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10, updatedFilterTransaction);
            clearFilters();
            confirm();
          }}
          filterTypeOptions={[
            { value: 'equals', label: 'Equal' },
            { value: 'lessthan', label: 'Less Than' },
            { value: 'greaterthan', label: 'Greater Than' },
          ]}
          placeholder="Enter the rent rate here"
        />
      ),
    },
    {
      title: "Chargeable Area",
      dataIndex: "chargeableSquareFeet",
      width: 150,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="number"
          filterValue={filterTransaction.chargeableSquareFeet}
          filterType={filterTransaction.chargeableSquareFeetFilter}
          onFilterChange={(value) => setFilterTransaction({ ...filterTransaction, chargeableSquareFeet: value })}
          onFilterTypeChange={(value) => setFilterTransaction({ ...filterTransaction, chargeableSquareFeetFilter: value })}
          onApply={() => {
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10);
            confirm();
          }}
          onReset={() => {
            const updatedFilterTransaction = { ...filterTransaction, chargeableSquareFeet: null, chargeableSquareFeetFilter: 'equals' }
            setFilterTransaction(updatedFilterTransaction)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10, updatedFilterTransaction);
            clearFilters();
            confirm();
          }}
          filterTypeOptions={[
            { value: 'equals', label: 'Equal' },
            { value: 'lessthan', label: 'Less Than' },
            { value: 'greaterthan', label: 'Greater Than' },
          ]}
          placeholder="Enter the chargeable square feet here"
        />
      ),
    },
    {
      title: "MicroMarket",
      dataIndex: "microMarket",
      width: 125,
      editable: true,
      render: (microMarket) =>
        Array.isArray(microMarket) && !isEmpty(microMarket) 
          ? microMarket.join(", "): null,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterTransaction.microMarket || []}
          onFilterChange={(value) => {
            setFilterTransaction({ ...filterTransaction, microMarket: value });
          }}
          onApply={() => {
            fetchTransactions(1, 10);
            confirm();
          }}
          onReset={() => {
            const updatedFilterTransaction = { ...filterTransaction, microMarket: [] }
            setFilterTransaction(updatedFilterTransaction)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10, updatedFilterTransaction);
            clearFilters();
            confirm();
          }}
          inputType="select"
          mode="multiple"
          placeholder="Select a Document Name"
          selectOptions={microMarketsData.map(microMarket => ({
            value: microMarket.value,
            label: microMarket.title,
          }))}
        />
      ),
      onFilter: (value, record) => record.microMarket.includes(value),
    },
    {
      title: "Average CAM",
      dataIndex: "CAM",
      width: 100,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="number"
          filterValue={filterTransaction.cam}
          filterType={filterTransaction.camFilter}
          onFilterChange={(value) => setFilterTransaction({ ...filterTransaction, cam: value })}
          onFilterTypeChange={(value) => setFilterTransaction({ ...filterTransaction, camFilter: value })}
          onApply={() => {
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10);
            confirm();
          }}
          onReset={() => {
            const updatedFilterTransaction = { ...filterTransaction, cam: null, camFilter: 'equals' }
            setFilterTransaction(updatedFilterTransaction)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10, updatedFilterTransaction);
            clearFilters();
            confirm();
          }}
          filterTypeOptions={[
            { value: 'equals', label: 'Equal' },
            { value: 'lessthan', label: 'Less Than' },
            { value: 'greaterthan', label: 'Greater Than' },
          ]}
          placeholder="Enter the CAM here"
        />
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      width: 150,
      render: (createdAt) =>
        createdAt ? dayjs(createdAt).format("DD-MM-YYYY") : null,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="dateRange"
          startDate={filterTransaction.createdAtStartDate}
          endDate={filterTransaction.createdAtEndDate}
          onStartDateChange={(date) => setFilterTransaction({ ...filterTransaction, createdAtStartDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute") })}
          onEndDateChange={(date) => setFilterTransaction({ ...filterTransaction, createdAtEndDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute") })}
          onApply={() => {
            fetchTransactions(1, 10, filterTransaction);
            confirm();
          }}
          onReset={() => {
            const updatedFilterTransaction = { ...filterTransaction, createdAtStartDate: null, createdAtEndDate: null }
            setFilterTransaction(updatedFilterTransaction)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10, updatedFilterTransaction);
            clearFilters();
            confirm();
          }}
        />
      ),
    },
    {
      title: "Completion Ratio",
      dataIndex: "completionRatio",
      width: 100,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="number"
          filterValue={filterTransaction.completionRatio}
          filterType={filterTransaction.completionRatioFilter}
          onFilterChange={(value) => setFilterTransaction({ ...filterTransaction, completionRatio: value })}
          onFilterTypeChange={(value) => setFilterTransaction({ ...filterTransaction, completionRatioFilter: value })}
          onApply={() => {
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10);
            confirm();
          }}
          
          onReset={() => {
            const updatedFilterTransaction = { ...filterTransaction, completionRatio: null, completionRatioFilter: 'equals' }
            setFilterTransaction(updatedFilterTransaction)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchTransactions(1, 10, updatedFilterTransaction);
            clearFilters();
            confirm();
          }}
          filterTypeOptions={[
            { value: 'equals', label: 'Equal' },
            { value: 'lessthan', label: 'Less Than' },
            { value: 'greaterthan', label: 'Greater Than' },
          ]}
          placeholder="Enter the completion ratio here"
        />
      ),
    },
    {
      title: "Index2",
      dataIndex: "index2",
      onCell: (record) => ({
        onClick: (e) => {
          if (record.index2 === null) {
            return;
          }
          e.stopPropagation();
          const documentDetails = {
            propertyName: record.propertyName,
            documentNo: record.documentNo,
            id: record._id,
            type: 'index2'
          }
          handleViewHtml(documentDetails);
        },
      }),
      width: 100,
      editable: false,
    },
    {
      title: "",
      dataIndex: "delete",
      width: "10%",
      editable: true,
      render: (_, record) => (
        <div
          style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(record._id);
          }}
        >
          <MdDeleteForever size={20} className="text-secondary" />
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchTransactions(currentPage, pageSize);
  }, [id, currentPage, pageSize, transactionFilters, localitiesData]);


  const fetchTransactions = (page, size, updatedFilterTransaction = filterTransaction) => {
    const adjustedPage = transactionsCnt % size === 1 && page > 1 ? page - 1 : page;
    if (
      updatedFilterTransaction?.buildingName ||
      updatedFilterTransaction?.documentNo ||
      updatedFilterTransaction?.documentName?.length > 0 ||
      updatedFilterTransaction?.registrationStartDate ||
      updatedFilterTransaction?.registrationEndDate ||
      updatedFilterTransaction?.locality?.length > 0 ||
      updatedFilterTransaction?.proposedAvailabilityStartDate ||
      updatedFilterTransaction?.proposedAvailabilityEndDate ||
      updatedFilterTransaction?.unitNo ||
      updatedFilterTransaction?.floorNo ||
      updatedFilterTransaction?.saleRate ||
      updatedFilterTransaction?.rentRate ||
      updatedFilterTransaction?.completionRatio ||
      updatedFilterTransaction?.chargeableSquareFeet ||
      updatedFilterTransaction?.microMarket?.length > 0 ||
      updatedFilterTransaction?.cam ||
      updatedFilterTransaction?.createdAtStartDate ||
      updatedFilterTransaction?.createdAtEndDate
    ) {
      const localitiesDataForFilter = transactionFilters?.localities.map(
        (loc) => localitiesData[loc]?.title
      );
      const body = {
        buildingName: updatedFilterTransaction?.buildingName,
        buildingNameFilter: updatedFilterTransaction?.buildingNameFilter || 'contains',
        documentNo: +updatedFilterTransaction?.documentNo || null,
        documentNoFilter: updatedFilterTransaction?.documentNoFilter || 'equals',
        documentName: updatedFilterTransaction?.documentName || [],
        registrationStartDate: updatedFilterTransaction?.registrationStartDate,
        registrationEndDate: updatedFilterTransaction?.registrationEndDate,
        locality: updatedFilterTransaction?.locality || [],
        proposedAvailabilityStartDate: updatedFilterTransaction?.proposedAvailabilityStartDate,
        proposedAvailabilityEndDate: updatedFilterTransaction?.proposedAvailabilityEndDate,
        unitNo: updatedFilterTransaction?.unitNo,
        unitNoFilter: updatedFilterTransaction?.unitNoFilter,
        floorNo: updatedFilterTransaction?.floorNo,
        floorNoFilter: updatedFilterTransaction?.floorNoFilter,
        saleRate: +updatedFilterTransaction?.saleRate || null,
        saleRateFilter: updatedFilterTransaction?.saleRateFilter || 'equals',
        rentRate: +updatedFilterTransaction?.rentRate || null,
        rentRateFilter: updatedFilterTransaction?.rentRateFilter || 'equals',
        completionRatio: +updatedFilterTransaction?.completionRatio || null,
        completionRatioFilter: updatedFilterTransaction?.completionRatioFilter || 'equals',
        chargeableSquareFeet: updatedFilterTransaction?.chargeableSquareFeet,
        chargeableSquareFeetFilter: updatedFilterTransaction?.chargeableSquareFeetFilter || 'equals',
        microMarket: updatedFilterTransaction?.microMarket || [],
        cam: +updatedFilterTransaction?.cam || null,
        camFilter: updatedFilterTransaction?.camFilter || 'equals',
        createdAtStartDate: updatedFilterTransaction?.createdAtStartDate,
        createdAtEndDate: updatedFilterTransaction?.createdAtEndDate,
        currentPage: page,
        pageSize: size,
        propertyId: id,
      };

      dispatch(getFilteredTransaction(body));
    } else {
      const body = {
        pageSize: size,
        page: adjustedPage,
        propertyId: id,
      };
      dispatch(getAllTransactions(body));
    }
  };

  const handlePaginationChange = (page, size) => {
    dispatch(setCurrentPage(page));
    if (size === pageSize) {
      fetchTransactions(page, size);
    } else {
      dispatch(setCurrentPage(1));
    }
  };

  const onShowSizeChange = (current, size) => {
    dispatch(setPageSize(size));
    dispatch(setCurrentPage(1));
    fetchTransactions(1, size);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await bulkUploadTransactions(file);
        const successMessage = response;
        successBulkUpload(successMessage);
        event.target.value = null;
        setTimeout(() => {
          dispatch(setCurrentPage(1));
          fetchTransactions(1, pageSize);
          event.target.value = null;
        }, 3000);
      } catch (error) {
        console.error("Failed to upload file:", error);
        const errorMessage = error.message;
        failBulkUpload(errorMessage);
      }
    }
  };

  const successBulkUpload = (messageContent) => {
    messageApi.open({
      type: "success",
      content: messageContent,
    });
  };

  const failBulkUpload = (messageContent) => {
    messageApi.open({
      type: "error",
      content: messageContent,
    });
  };

  return (
    <React.Fragment>
      {loading ? (
        <Spin spinning={loading} size="large" fullscreen />
      ) : (
        <>
          {contextHolder}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "auto",
              flexGrow: 1,
            }}>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="m-4 pl-4">
                <h1 style={{ fontSize: "18px", fontWeight: "bold" }}>Transaction</h1>
              </div>
              <div className="list-options m-4 flex">
                <a href="/Transaction_Sample_Data.xlsx" download>
                  <Button className="mr-4" color="primary">
                    <img src={DownloadIcon} alt="download" />
                    Download Sample
                  </Button>
                </a>
                <Button
                  className="mr-4"
                  onClick={() => document.getElementById("bulkUploadInput").click()}
                >
                  <img src={BulkUpload} alt="bulk" />
                  Bulk Upload
                </Button>
                <input
                  type="file"
                  id="bulkUploadInput"
                  accept=".xlsx, .xls"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                <Button
                  className="mr-4"
                  style={{ backgroundColor: "#fb923c", color: "#FFFFFF" }}
                  onClick={() => {
                    dispatch(setTransactionInitialState());
                    navigateTo("/transactionDetails");
                    dispatch(setTransactionId(null));
                  }}
                >
                  <img src={AddProperty} alt="add" />
                  Add Transaction
                </Button>
                <ReloadOutlined
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  title="Reload Property List"
                  onClick={() => {
                    dispatch(setCurrentPage(1));
                    dispatch(setPageSize(10));
                    const updatedFilterTransaction = {
                      buildingName: null,
                      buildingNameFilter: 'contains',
                      documentNo: null,
                      documentNoFilter: 'equals',
                      documentName: [],
                      registrationStartDate: null,
                      registrationEndDate: null,
                      locality: [],
                      proposedAvailabilityStartDate: null,
                      proposedAvailabilityEndDate: null,
                      unitNo: null,
                      unitNoFilter: 'contains',
                      floorNo: null,
                      floorNoFilter: 'contains',
                      saleRate: null,
                      saleRateFilter: 'equals',
                      rentRate: null,
                      rentRateFilter: 'equals',
                      completionRatio: null,
                      completionRatioFilter: 'equals',
                      chargeableSquareFeet: null,
                      chargeableSquareFeetFilter: 'equals',
                      microMarket: [],
                      cam: null,
                      camFilter: 'equals',
                      createdAtStartDate: null,
                      createdAtEndDate: null
                    }
                    setFilterTransaction(updatedFilterTransaction);
                    fetchTransactions(1, 10, updatedFilterTransaction);
                  }}
                />
              </div>
            </div>
            <div className="m-4" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', backgroundColor: '#FFFFFF', borderRadius: '8px' }}>
              <Table
                className="no-column-lines"
                bordered
                dataSource={transactionsDetailsData?.data}
                columns={columns}
                size="small"
                loading={loading}
                rowKey={"_id"}
                pagination={false}
                scroll={{ x: 300 }}
                style={{ width: "auto" }}
                onRow={(record) => {
                  return {
                    onClick: () => handleView(record._id),
                    style: { cursor: "pointer" },
                  };
                }}
              />

              {transactionsDetailsData?.data?.length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                  <div style={{ margin: '10px 0' }}>
                    Showing {((currentPage - 1) * pageSize) + 1} - {Math.min(currentPage * pageSize, transactionsCnt)} / {transactionsCnt} Results
                  </div>
                  <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    onChange={(currPage, size) =>
                      handlePaginationChange(currPage, size)
                    }
                    total={transactionsCnt}
                    style={{ marginTop: "10px", marginBottom: "20px" }}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default Transactions;
